// 用户管理
import request from '@/utils/request'
// 获取用户列表
export function getUsersList(params) {
  return request({
    url: '/user/page',
    method: 'get',
    params
  });
};
// 新增用户
export function AddUsersList(data) {
  return request({
    url: '/user/save',
    method: 'post',
    data
  });
}
// 删除用户
export function deleteUsersList(data) {
  return request({
    url: '/user',
    method: 'delete',
    data
  });
}
// 获取用户详情
export function getUserDetail(params) {
  return request({
    url: '/user',
    method: 'get',
    params
  });
}
// 修改用户
export function updateUser(data1,data) {
  return request({
    url: `/user/${data1}`,
    method: 'post',
    data
  });
}
// 重置密码
export function updatePassword(params) {
  return request({
    url: '/user',
    method: 'post',
    params
  });
}
// 修改用户的状态
export function updateUserStatus(data) {
  return request({
    url: '/user/status',
    method: 'post',
    data
  });
}