// 角色管理
import request from '@/utils/request'
// 获取角色列表
export function getRoleList(params) {
  return request({
    url: '/role/list',
    method: 'get',
    params
  });
}
// 添加角色
export function addRoles(data) {
  return request({
    url: '/role',
    method: 'post',
    data
  });
}
// 删除角色
export function deleteRoles(params) {
  return request({
    url: '/role',
    method: 'delete',
    params
  });
}
// 获取角色详情
export function editRoles(data) {
  return request({
    url: '/role',
    method: 'post',
    data
  });
}
// 编辑角色
export function getDetail(params) {
  return request({
    url: '/role',
    method: 'get',
    params
  });
}
// 获取未选择角色人员
export function notMember(params) {
  return request({
    url: '/role/user',
    method: 'get',
    params
  });
}
// 获取权限列表
export function getJurisdictionList(params) {
  return request({
    url: '/role/menuList',
    method: 'get',
    params
  });
}
// 设置权限
export function updateJurisdictionList(data) {
  return request({
    url: '/role/update/menu',
    method: 'post',
    data
  });
}
// 获取权限用户列表
export function getJurisdictionUserList(params) {
  return request({
    url: '/role/admin/page',
    method: 'get',
    params
  });
}
// 给用户授权
export function setJurisdictionUser(data) {
  return request({
    url: '/role/authority',
    method: 'post',
    data
  });
}
// 获取用户的权限
export function getUserJurisdiction(params) {
  return request({
    url: '/role/authority',
    method: 'get',
    params
  });
}