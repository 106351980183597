<template>
    <div class="userList">
        <!-- 用户列表 -->
        <div class="searchBtn">
            <!-- <div class="inputContent"> -->
            <el-input placeholder="请输入姓名" v-model="searchInfo.nickname" clearable size="small">
            </el-input>
            <el-input placeholder="请输入登录名" v-model="searchInfo.username" clearable size="small">
            </el-input>
            <el-input placeholder="请输入电话" v-model="searchInfo.telephone" type="number" clearable size="small">
            </el-input>
            <el-select v-model="searchInfo.sex" placeholder="请选择性别" size="small">
                <el-option label="全部" :value="4">
                </el-option>
                <el-option label="男" :value="1">
                </el-option>
                <el-option label="女" :value="2">
                </el-option>
                <el-option label="未填写" :value="3">
                </el-option>

            </el-select>
            <el-cascader :options="OrganizationsList" v-model="searchInfo.departmentId" size="small" placeholder="请选择组织机构"
                :show-all-levels="false"
                :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }"></el-cascader>

            <el-cascader :options="RolesList" placeholder="请选择角色" v-model="searchInfo.roleId" size="small"
                :props="{ value: 'id', label: 'name', emitPath: false }" :show-all-levels="false"></el-cascader>
            <!-- <el-select v-model="searchInfo.roleId" placeholder="请选择角色" size="small">
                <el-option v-for="item in RolesList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select> -->
            <!-- </div> -->
            <!-- <div class="btn"> -->
            <el-button type="primary" size="small" @click="search">搜索</el-button>
            <el-button type="primary" size="small" @click="add">新增</el-button>
            <el-button type="primary" size="small" @click="userImport">导入</el-button>
            <!-- <el-dropdown split-button type="primary" @command="DownloadUser" size="small">导出
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="1">导出所选</el-dropdown-item>
                    <el-dropdown-item :command="2">导出全部</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown> -->
            <el-button type="primary" size="small" @click="DownloadUser">导出</el-button>
            <el-button type="primary" size="small" @click="deleteUser">删除</el-button>

        </div>
        <div class="tableContent">
            <el-table :data="tableData" border style="width: 100%" size="small" @selection-change="handleSelectionChange">
                <el-table-column type="selection">
                </el-table-column>

                <el-table-column label="编号" width="100" fixed>
                    <template slot-scope="scope">
                        <span>{{ scope.row.id }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="nickname" label="姓名" width="120" show-overflow-tooltip fixed>
                </el-table-column>

                <el-table-column prop="province" label="性别" width="80" fixed>
                    <template slot-scope="scope">
                        <span v-if="scope.row.sex === 1">男</span>
                        <span v-else-if="scope.row.sex === 2">女</span>
                        <span v-else-if="scope.row.sex === 3">未填写</span>
                    </template>
                </el-table-column>
                <el-table-column prop="birthday" label="出生日期" width="150"></el-table-column>
                <el-table-column prop="telephone" label="手机号" width="120"></el-table-column>
                <el-table-column prop="username" label="登录名" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="学历" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.education === 1">初等教育</span>
                        <span v-if="scope.row.education === 2">中等教育</span>
                        <span v-if="scope.row.education === 3">高等教育</span> -->
                        <span v-if="scope.row.education === 1">小学及以下</span>
                        <span v-if="scope.row.education === 2">初中</span>
                        <span v-if="scope.row.education === 3">高中（含技校）</span>
                        <span v-if="scope.row.education === 4">大学（含大专）</span>
                        <span v-if="scope.row.education === 5">研究生及以上</span>
                    </template>
                </el-table-column>
                <el-table-column label="职业" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.occupation === 1">国家机关、党群组织、企业、事业单位负责人</span>
                        <span v-if="scope.row.occupation === 2">专业技术人员</span>
                        <span v-if="scope.row.occupation === 3">办事人员和有关人员</span>
                        <span v-if="scope.row.occupation === 4">商业、服务业人员</span>
                        <span v-if="scope.row.occupation === 5">农、林、牧、渔、水利业生产人员</span>
                        <span v-if="scope.row.occupation === 6">生产、运输设备操作人员及有关人员</span>
                        <span v-if="scope.row.occupation === 7">军人</span>
                        <span v-if="scope.row.occupation === 8">学生
                            <span v-if="scope.row.grade === 1">
                                小学1-3
                            </span>
                            <span v-if="scope.row.grade === 2">
                                初中1-3"
                            </span>
                            <span v-if="scope.row.grade === 3">
                                高中1-3
                            </span>
                            <span v-if="scope.row.grade === 4">
                                大学1-4
                            </span>
                        </span>
                        <span v-if="scope.row.occupation === 9">不便分类的其他从业人员</span>

                    </template>
                </el-table-column>
                <!-- <el-table-column prop="grade" label="年级" width="150" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column prop="departmentName" :show-overflow-tooltip="true" label="组织机构" width="150">
                </el-table-column>
                <el-table-column prop="roleName" label="角色名称" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="openid" label="openId" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="weChatNickname" label="微信昵称" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column prop="weChat" label="微信号" width="160" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="qq" label="QQ号" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column prop="source" label="用户来源" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.source === 1"> 后台新增</span>
                        <span v-if="scope.row.source === 2"> 用户注册</span>
                        <span v-if="scope.row.source === 3"> 后台导入</span>
                        <span v-if="scope.row.source === 4"> 游客</span>
                        <span v-if="scope.row.source === 5"> 微信登录</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="160">
                </el-table-column>
                <el-table-column prop="username" label="禁用">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" @change="changeStatus(scope.row)" :active-value="1"
                            :inactive-value="0">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="打印报告">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isPrint" @change="changePrint(scope.row)" :active-value="1"
                            :inactive-value="0">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="edit(scope.row.id)">编辑</el-button>
                        <el-button type="text" size="small" @click="updatePWD(scope.row)">重置密码</el-button>
                        <!-- <el-button type="text" size="small" @click="deleteUser(scope.row.id)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total, prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
            <!-- <el-pagination background layout="prev, pager, next" :total="paging.total"
                @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps">
            </el-pagination> -->
        </div>
        <EditUserVue ref="editChild" @getUserList="getUserList"></EditUserVue>
        <AddUserVue ref="addChild" @getUserList="getUserList"></AddUserVue>
        <ImportUserVue ref="importChild" @getUserList="getUserList"></ImportUserVue>
    </div>
</template>

<script>
import { getUsersList, deleteUsersList, updateUserStatus, updateUser } from '@/api/system/users'
import { getOrganizationList } from '@/api/system/organization'
import { getRoleList } from '@/api/system/role'
import AddUserVue from './AddUser.vue'
import EditUserVue from './EditUser.vue'
import ImportUserVue from './ImportUser.vue'
export default {
    data() {
        return {
            searchInfo: {
                nickname: null,
                sex: null,
                departmentId: null,
                roleId: null,
                username: null,
                telephone: null

            },
            options: [],
            value: '',
            tableData: [], //用户列表
            OrganizationsList: [], //组织机构列表
            RolesList: [], // 角色列表
            paging: {
                p: 1,
                ps: 20,
                total: 0
            },
            deleteUserIdList: []
        }
    },
    methods: {
        // 获取用户列表
        async getUserList() {
            let info = JSON.parse(JSON.stringify(this.searchInfo));
            console.log(info);
            if (info.sex === 4) {
                info.sex = null;
            }
            if (info.departmentId === -1) {
                info.departmentId = null;
            }
            if (info.roleId === -1) {
                info.roleId = null;
            }
            const res = await getUsersList({ p: this.paging.p, ps: this.paging.ps, ...info });
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.userVOList;
                this.paging.total = res.data.dataCount;
                console.log(this.paging.total)
            }
        },
        // 切换分页
        handleCurrentChange(val) {
            this.paging.p = val;
            this.getUserList()
        },
        // 获取组织机构列表
        async getOrganizationsList() {
            const res = await getOrganizationList()
            console.log('组织机构');
            console.log(res);
            if (res.code === 200) {
                this.OrganizationsList = res.data;
                this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
                // this.deleteNullChildren(this.OrganizationsList)
            }
        },
        // 获取角色列表
        async getRolesList() {
            const res = await getRoleList();
            console.log(res);
            if (res.code === 200) {
                this.RolesList = res.data;
                this.RolesList = [{ id: -1, name: '全部' }, ...this.RolesList]
            }
        },
        // 点击搜索
        async search() {
            // console.log(this.searchInfo);
            // if (this.searchInfo.sex===4) {
            //     this.searchInfo.sex = null;
            // }
            // if(this.searchInfo.departmentId === -1){
            //     this.searchInfo.departmentId = null;
            // }
            // if(this.searchInfo.roleId === -1){
            //     this.searchInfo.roleId = null;
            // }
            this.paging.p = 1;
            this.getUserList();
        },
        // 使用递归取出children为空的数据
        deleteNullChildren(val) {
            console.log(val);
            if (val) {
                val.forEach(item => {
                    if (item.children.length < 1) {
                        delete item.children;
                    }
                    this.deleteNullChildren(item.children)
                });
            }
        },
        // 获取选择的用户id
        handleSelectionChange(val) {
            // console.log(val);
            this.deleteUserIdList = [];
            if (val.length > 0) {
                val.forEach(item => {
                    this.deleteUserIdList.push(item.id);
                })
            };
            // console.log(this.deleteUserIdList)
        },
        // 删除用户
        deleteUser(val) {
            if (this.deleteUserIdList.length > 0) {
                this.$confirm('确认删除该用户?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    console.log(typeof (val));
                    if (typeof (val) === 'number') {
                        this.deleteUserIdList = [val];
                    }
                    console.log(this.deleteUserIdList);
                    const res = await deleteUsersList(JSON.stringify(this.deleteUserIdList));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getUserList();
                    } else {
                        this.$message({
                            showClose: true,
                            type: 'error',
                            message: '删除失败!' + res.msg
                        });
                    }
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '请选择需要删除的用户！'
                });
            }
        },
        // 新增
        add() {
            // console.log(111)
            this.$refs.addChild.openAdd();
        },
        // 编辑
        edit(val) {
            this.$refs.editChild.openEdit(val)
        },
        // 重置密码
        updatePWD(val) {
            console.log(val);
            this.$prompt(`您正在修改 ${val.username} 的密码`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: '请输入新密码',
                showClose: false,
                type: 'warning'
            }).then(async ({ value }) => {
                if (value !== null) {
                    const res = await updateUser(val.id, JSON.stringify({ password: value, roleId: val.roleId }));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.getUserList();
                    } else {
                        this.$message({
                            showClose: true,
                            type: 'error',
                            message: '操作失败!' + res.msg
                        });
                    }
                }
                // this.$message({
                //     type: 'success',
                //     message: '你的邮箱是: ' + value
                // });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });

            // this.$confirm('确认将密码重置为 123456 ?', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(async () => {
            //     const res = await updatePassword({ id: val });
            //     console.log(res);
            //     if (res.code === 200) {
            //         this.$message({
            //             showClose: true,
            //             type: 'success',
            //             message: '操作成功!'
            //         });
            //         this.getUserList();
            //     } else {
            //         this.$message({
            //             showClose: true,
            //             type: 'error',
            //             message: '操作失败!' + res.msg
            //         });
            //     }
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '已取消删除'
            //     });
            // });
        },
        // 点击导入
        userImport() {
            this.$refs.importChild.openImport();
        },
        // // 导出用户
        // DownloadUser(val) {
        //     // 导出所选
        //     if (val === 1) {
        //         if (this.deleteUserIdList.length > 0) {
        //             let userAnswerIds = [...this.deleteUserIdList];
        //             let list = userAnswerIds.join(',');
        //             const elink = document.createElement('a');
        //             elink.href = `${this.$store.state.requestApi}/user/export?ids=${list}`;
        //             elink.click();
        //         } else {
        //             this.$message({
        //                 showClose: true,
        //                 message: '请选择需要导出的用户!'
        //             });
        //         }
        //     } else { // 导出全部

        //     }


        // },
        // 导出用户
        DownloadUser() {
            if (this.deleteUserIdList.length > 0) {
                let userAnswerIds = [...this.deleteUserIdList];
                let list = userAnswerIds.join(',');
                const elink = document.createElement('a');
                elink.href = `${this.$store.state.requestApi}/user/export?ids=${list}`;
                elink.click();
            } else {
                this.$message({
                    showClose: true,
                    message: '请选择需要导出的用户!'
                });
            }

        },
        // 修改用户的状态
        async changeStatus(val) {
            console.log(val);
            const info = new FormData()
            info.append("id", val.id);
            info.append("status", val.status);
            console.log(info);
            const res = await updateUserStatus(info);
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '修改成功！',
                    type: 'success'
                });
                this.getUserList();
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败！' + res.data,
                    type: 'error'
                });
            }
        },
        // 修改用户的是否能打印
        async changePrint(val) {
            console.log(val);
            const info = new FormData()
            info.append("id", val.id);
            info.append("isPrint", val.isPrint);
            console.log(info);
            const res = await updateUserStatus(info);
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '修改成功！',
                    type: 'success'
                });
                this.getUserList();
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败！' + res.data,
                    type: 'error'
                });
            }
        }


    },
    created() {
        this.getUserList();
        this.getRolesList();
        this.getOrganizationsList();
    },
    components: {
        AddUserVue, EditUserVue, ImportUserVue
    }
}
</script>

<style lang="scss" scoped>
.userList {
    padding: 20px;

    .searchBtn {

        // // 取消数字输入框后的上下减
        ::v-deep input::-webkit-outer-spin-button,
        ::v-deep input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
        }

        ::v-deep input[type='number'] {
            -moz-appearance: textfield !important;
        }
    }


    // .search {
    //     display: flex;
    //     width: 100%;

    //     // .inputContent {

    //     .el-input,
    //     .el-select,
    //     .el-cascader {
    //         width: 150px;
    //         margin-right: 30px;
    //     }

    //     // }

    //     // .btn {
    //     .el-button {
    //         margin-left: 40px;
    //         display: inline-block;
    //     }

    //     // }

    // }

    .tableContent {
        margin-top: 20px;

        // ::v-deep.el-table .el-table__body .el-table__cell {
        //     height: 32px !important;
        //     padding: 0px !important;
        //     line-height: 32px !important;
        // }

        // ::v-deep.el-table th.el-table__cell {
        //     height: 35px !important;
        //     padding: 0px !important;
        //     line-height: 35px !important;
        // }

        ::v-deep.el-table__body {
            .el-table__cell {
                padding: 0 !important;
            }
        }
    }

    .block {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
}
</style>