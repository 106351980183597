<template>
    <div class="import">
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
            <div class="content">
                <div class="note">
                    <p>注意事项</p>
                    <span>1. 红色部分为必填信息，请按照表格依次填写;</span>
                    <br />
                    <span>2. 出生日期年月日之间用&nbsp;"/"&nbsp;分割(例如：2000/1/1);</span>
                    <br />
                    <span>3. 文件类型仅支持：*.xls、*.xlsx;</span>
                    <br />
                    <span>4. 文件大小最好不要超过4MB;</span>
                    <br />
                    <span>5. 新导入用户登录密码默认为 123456。</span>
                     <br />
                    <!-- <span>6. 学历： 1.</span> -->
                </div>
                <div class="importContent">
                    <p>导入流程</p>
                    <span>1.<el-button type="danger" size="mini" style="margin:0 10px;"><a
                                :href="$store.state.downLoadUrl +'/导入用户.xlsx'">下载模板</a></el-button>按照注意事项填写相关信息
                    </span>
                    <br />
                    <span style="display:flex; align-items: center;">2.
                        <!-- <el-upload class="upload-demo" ref="upload"
                            action="http://101.34.136.224:8080/user/improt" :on-change="upLoadChange" :on-success="successFile"
                            :auto-upload="false" :show-file-list="false" :headers="{ token: token }">
                            <el-button style="margin:0px 10px;" slot="trigger" size="mini" type="danger">选取文件
                            </el-button>
                        </el-upload> -->
                        <!-- action="http://xbssc.zhihanyixing.com/api/user/improt" -->
                        <el-upload class="upload-demo" ref="upload"
                        :action="$store.state.requestApi + '/user/improt'" :on-change="upLoadChange" :on-success="successFile"
                            :auto-upload="false" :limit="2" :show-file-list="false" :headers="{ token: token }">
                            <el-button style="margin:0px 10px;" slot="trigger" size="mini" type="danger">选取文件
                            </el-button>
                        </el-upload>
                        即可导入成功
                    </span>
                    <span v-if="fileList.name">(已选取文件 <span style="color: #409eff;">{{ fileList.name }}</span>)</span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" @click="submitUpload">上 传</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            fileList: [],
            token: null,
        };
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        // 打开当前模态框
        openImport() {
            this.dialogVisible = true;
            this.fileList = []
        },
        submitUpload() {
            // console.log(111)
            this.$refs.upload.submit();
        },
        upLoadChange(file, fileList) {
            console.log(file);
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            this.fileList = fileList[0];
            // if (file.response.code === 200) {
            //     this.$message({
            //         showClose: true,
            //         message: '导入成功！',
            //         type: 'success'
            //     });
            // } else {
            //     this.$message({
            //         showClose: true,
            //         message: '导入失败！' + file.response.msg ,
            //         type: 'error'
            //     });
            // }
            // console.log(fileList);
        },
        successFile(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            // console.log(fileList);
            if (response.code === 200) {
                this.$message({
                    showClose: true,
                    message: '导入成功！',
                    type: 'success'
                });
                this.dialogVisible = false;
                this.$emit('getUserList');
            } else {
                this.$message({
                    showClose: true,
                    message: '导入失败！' + response.msg ,
                    type: 'error'
                });
            }
        }
    },
    mounted() {
        this.token = sessionStorage.getItem('token')
    }
}
</script>

<style lang="scss" scoped>
.import {
    .content {
        p {
            font-size: 14px;
            color: #303030;
            line-height: 20px;
        }

        span {
            display: inline-block;
            margin-top: 10px;
        }

        .importContent {
            a {
                text-decoration: none;
                color: white;
            }
        }
    }
}
</style>