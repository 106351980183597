<template>
    <div class="edit">
        <el-dialog title="编辑用户" :visible.sync="dialogVisible" width="30%" :show-close="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
                <el-form-item prop="nickname">
                    <span>姓名</span><br />
                    <el-input v-model="ruleForm.nickname"></el-input>
                </el-form-item>
                <el-form-item prop="sex">
                    <span>性别</span>
                    <br />
                    <el-select v-model="ruleForm.sex" placeholder="请选择性别">
                        <el-option label="男" :value="1"></el-option>
                        <el-option label="女" :value="2"></el-option>
                        <el-option label="未填写" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="telephone">
                    <span>手机号</span><br />
                    <el-input v-model="ruleForm.telephone" type="number"></el-input>
                </el-form-item>
                <el-form-item>
                    <span>出生日期</span>
                    <br />
                    <el-date-picker :picker-options="pickerOptions" v-model="ruleForm.birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy 年 MM 月 dd 日"
                     >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <span>学历</span>
                    <br />
                    <el-select v-model="ruleForm.education" placeholder="请选择学历">
                        <!-- <el-option label="初等教育" :value="1"></el-option>
                        <el-option label="中等教育" :value="2"></el-option>
                        <el-option label="高等教育" :value="3"></el-option> -->
                        <el-option label="小学及以下" :value="1"></el-option>
                    <el-option label="初中" :value="2"></el-option>
                    <el-option label="高中（含技校）" :value="3"></el-option>
                    <el-option label="大学（含大专）" :value="4"></el-option>
                    <el-option label="研究生及以上" :value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <span>职业</span>
                    <br />
                    <el-select v-model="ruleForm.occupation" placeholder="请选择职业">
                        <el-option label="国家机关、党群组织、企业、事业单位负责人" :value="1"></el-option>
                        <el-option label="专业技术人员" :value="2"></el-option>
                        <el-option label="办事人员和有关人员" :value="3"></el-option>
                        <el-option label="商业、服务业人员" :value="4"></el-option>
                        <el-option label="农、林、牧、渔、水利业生产人员" :value="5"></el-option>
                        <el-option label="生产、运输设备操作人员及有关人员" :value="6"></el-option>
                        <el-option label="军人" :value="7"></el-option>
                        <el-option label="学生" :value="8"></el-option>
                        <el-option label="不便分类的其他从业人员" :value="9"></el-option>
                        <!-- <el-option :label="null" :value="3"></el-option> -->
                    </el-select>
                </el-form-item>
                <el-form-item v-show="ruleForm.occupation === 8">
                    <span>年级</span>
                    <br />
                    <el-select v-model="ruleForm.grade" placeholder="请选择年级">
                        <el-option label="小学1-6" :value="1"></el-option>
                        <el-option label="初中1-3" :value="2"></el-option>
                        <el-option label="高中1-3" :value="3"></el-option>
                        <el-option label="大学1-4" :value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="username">
                    <span>登录名</span><br />
                    <el-input v-model="ruleForm.username" disabled></el-input>
                </el-form-item>
                <!-- <el-form-item prop="password">
                    <span>登录密码</span><br />
                    <el-input v-model="ruleForm.password"></el-input>
                </el-form-item> -->
                <el-form-item prop="departmentId">
                    <span>组织机构</span>
                    <br />
                    <!-- <el-cascader :options="OrganizationsList" v-model="ruleForm.departmentId" size="small"
                        :show-all-levels="false" clearable :props="{ value: 'id', label: 'name', checkStrictly: true }">
                    </el-cascader> -->
                    <el-cascader :options="OrganizationsList" v-model="ruleForm.departmentId" size="small" :show-all-levels="false" :props="{ value: 'id', label: 'name'}" clearable></el-cascader>
                </el-form-item>
                <el-form-item prop="roleId">
                    <span>角色名称</span>
                    <br />
                    <!-- <el-select v-model="ruleForm.roleId" placeholder="请选择角色" clearable>
                        <el-option :label="item.name" :value="item.id" v-for="item in RolesList" :key="item.id">
                        </el-option>
                    </el-select> -->
                    <el-cascader :options="RolesList"  placeholder="请选择角色" v-model="ruleForm.roleId" size="small" :props="{ value: 'id', label: 'name', emitPath: false}" :show-all-levels="false"></el-cascader>
                </el-form-item>
                <el-form-item >
                    <span>微信号</span>
                    <br />
                    <el-input v-model="ruleForm.weChat"></el-input>
                </el-form-item>
                <el-form-item >
                    <span>QQ号</span>
                    <br />
                    <el-input v-model="ruleForm.qq"></el-input>
                </el-form-item>
                
            </el-form>
            <!-- <span>这是一段信息</span> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getOrganizationList } from '@/api/system/organization'
import { getRoleList } from '@/api/system/role'
import { updateUser, getUserDetail } from '@/api/system/users'
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                username: null,
                sex: 3,
                birthday: null,
                departmentId: null,
                nickname: null, // 昵称
                telephone: null,
                roleId: null,
                grade: null,
                occupation: null,
                qq: null,
                weChat: null,
                education: null
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6; // 如果没有后面的-8.64e6就 是不可以选择今天的
                }
            },
            userId: null,
            OrganizationsList: [],
            RolesList: [],
            rules: {
                username: [
                    { max: 10, message: '长度在 10 个字符以内', trigger: 'change' }
                ],
                nickname: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { max: 15, message: '长度在 15 个字符以内', trigger: 'change' }
                ],
                telephone: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'change' }
                ],
                birthday: [
                    { required: true, message: '请选择出生日期', trigger: 'change' }
                ],
                roleId: [
                    { required: true, message: '请选择角色名称', trigger: 'change' }
                ],
                departmentId: [
                    { required: true, message: '请选择组织机构', trigger: 'change' }
                ]
            }
        };
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        // 打开当前模态框
        openEdit(val) {
            this.getDetail(val)
            this.dialogVisible = true;
            this.getOrganizationsList();
            this.getRolesList();
        },
        // 获取组织机构列表
        async getOrganizationsList() {
            const res = await getOrganizationList()
            // console.log('组织机构');
            // console.log(res);
            if (res.code === 200) {
                this.OrganizationsList = res.data;
                // this.deleteNullChildren(this.OrganizationsList);
            }
        },
        // 获取角色列表
        async getRolesList() {
            const res = await getRoleList();
            // console.log(res);
            if (res.code === 200) {
                this.RolesList = res.data;
            }
        },
        // 使用递归取出children为空的数据
        deleteNullChildren(val) {
            // console.log(val);
            if (val) {
                val.forEach(item => {
                    if (item.children.length < 1) {
                        delete item.children;
                    }
                    this.deleteNullChildren(item.children)
                });
            }
        },
        // 获取用户详情
        async getDetail(val) {
            const res = await getUserDetail({ id: val })
            console.log(res);
            if (res.code === 200) {
                this.ruleForm.username = res.data.username;
                this.ruleForm.sex = res.data.sex;
                this.ruleForm.birthday = res.data.birthday;
                this.ruleForm.nickname = res.data.nickname;
                this.ruleForm.password = res.data.password;
                this.ruleForm.roleId = res.data.roleId;
                this.ruleForm.qq = res.data.qq;
                this.ruleForm.weChat = res.data.weChat;
                this.ruleForm.telephone = res.data.telephone;
                this.ruleForm.departmentId = res.data.departmentIds;
                this.ruleForm.education = res.data.education;
                this.ruleForm.grade = res.data.grade;
                this.ruleForm.occupation = res.data.occupation;
                this.userId = res.data.id;
            }
        },
        // 点击提交
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // alert('submit!');
                    console.log(this.ruleForm);
                    if(this.ruleForm.telephone === '') {
                        this.ruleForm.telephone = null;
                    }
                    const res = await updateUser(this.userId, JSON.stringify(this.ruleForm));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '修改成功！',
                            type: 'success'
                        });
                        this.dialogVisible = false;
                        this.$emit('getUserList');
                    } else {
                        this.$message({
                            showClose: true,
                            message: '修改失败！' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }

}
</script>

<style lang="scss" scoped>
.edit {
    ::v-deep.el-dialog {
        height: 70%;
        overflow-y: scroll;
        // position: relative;
    }

    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }

    ::v-deep input[type='number'] {
        -moz-appearance: textfield !important;
    }

    // height: 80%;
    .el-input,
    .el-select,
    .el-cascader {
        width: 100%;
    }
}
</style>